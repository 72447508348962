import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/index',
        component: () => import(/*webpackChunkName:'DefaultContainer'*/ '@/container/DefaultContainer.vue'),
        children: [
            {
                // 欢迎首页
                path: 'index',
                name: 'index',
                meta: {
                    title: '首页',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Index'*/ '@/page/index.vue')
            },

            {
                // 人员
                path: 'admin',
                name: 'admin',
                meta: {
                    title: '人员',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Admin'*/ '@/page/role/admin.vue')
            },
            {
                // 角色
                path: 'role',
                name: 'role',
                meta: {
                    title: '角色',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Role'*/ '@/page/role/role.vue')
            },
            {
                // 用户
                path: 'user',
                name: 'user',
                meta: {
                    title: '用户',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'User'*/ '@/page/role/user.vue')
            },
            {
                // 公司
                path: 'company',
                name: 'company',
                meta: {
                    title: '公司',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'CompanyList'*/ '@/page/resource/company.vue')
            },
            {
                // 标签
                path: 'category',
                name: 'category',
                meta: {
                    title: '标签',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'CategoryList'*/ '@/page/resource/category.vue')
            },
            {
                // 剧集
                path: 'drama',
                name: 'drama',
                meta: {
                    title: '剧集',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'DramaList'*/ '@/page/drama/drama.vue')
            },
            {
                // 单集
                path: 'episodes',
                name: 'episodes',
                meta: {
                    title: '单集',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'EpisodeList'*/ '@/page/drama/episodes.vue')
            },
            {
                // 全部单集
                path: 'allepisodes',
                name: 'allepisodes',
                meta: {
                    title: '全部单集',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'EpisodeList'*/ '@/page/drama/allepisodes.vue')
            },
            {
                // 轮播
                path: 'banner',
                name: 'banner',
                meta: {
                    title: '轮播',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Banner'*/ '@/page/section/banner.vue')
            },
            {
                // 推荐
                path: 'recom',
                name: 'recom',
                meta: {
                    title: '推荐',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Recom'*/ '@/page/section/recom.vue')
            },
            {
                // 推荐详情
                path: 'recomdetail',
                name: 'recomdetail',
                meta: {
                    title: '推荐详情',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'RecomDetail'*/ '@/page/section/recomdetail.vue')
            },
            {
                // 消息
                path: 'message',
                name: 'message',
                meta: {
                    title: '消息',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Message'*/ '@/page/section/message.vue')
            },
            {
                // 充值
                path: 'recharge',
                name: 'recharge',
                meta: {
                    title: '充值记录',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Recharge'*/ '@/page/transaction/recharge.vue')
            },
            {
                // 支付
                path: 'billing',
                name: 'billing',
                meta: {
                    title: '支付设置',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Billing'*/ '@/page/transaction/billing.vue')
            },
            {
                // 邀请码
                path: 'invitation',
                name: 'invitation',
                meta: {
                    title: '邀请码',
                    requiresAuth: true
                },
                component: () => import(/*webpackChunkName:'Invitation'*/ '@/page/transaction/invitation.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import(/*webpackChunkName:'Login'*/ '@/page/auth/login.vue')
    }
]

const routerMode = 'hash'

const router = createRouter({
    history: routerMode === 'hash' ? createWebHashHistory() : createWebHistory(),

    scrollBehavior() {
        return { top: 0, left: 0 } // 或者使用 { y: 0 }
    },
    routes
})

router.beforeEach((to, from, next) => {
    // 检查路由是否需要认证
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const tokenValue = localStorage.getItem('tokenValue')

        // 检查token是否存在
        if (!tokenValue) {
            // 如果目标路由不是登录页，才重定向到登录页面
            if (to.path !== '/login') {
                next({ path: '/login', query: { redirect: to.fullPath } })
            } else {
                // 如果已经是登录页，不进行任何操作，直接通过
                next()
            }
        } else {
            // 假设你有一个方法用于检查token是否有效
            // 这里只是演示，实际情况下你需要向后端发起请求或使用其他逻辑来验证
            // 如果token有效，则继续导航
            next()
        }
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = `${to.meta.title} | 1633short Manager`
    }
})

export default router
export { routerMode } // 将 routerMode 作为一个单独的导出
