import { vue, createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import router from './router/index.js'
import instance from './api/handler'
import urls from './api/api.js'
import tips from './config/tips.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'normalize.css'
import '@/assets/main.css'
import '@/styles/index.scss'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus, { locale: zhCn })
app.use(router)
app.config.globalProperties.$urls = urls
app.config.globalProperties.$instance = instance
app.config.globalProperties.$tips = tips
app.mount('#app')
