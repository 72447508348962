// axios 请求
import axios from 'axios'
import { Base64 } from 'js-base64'
import { ElMessageBox } from 'element-plus'

// 创建通用配置
let instance = axios.create({
    responseType: 'json',
    headers: { 'Content-Type': 'application/json' }
})

// 对token加密
function baseFun(token) {
    const base64 = Base64.encode(token)
    return base64
}

// http请求前拦截
instance.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('gp-token')
        if (token) {
            config.headers.set('gp-token', baseFun(token))
        }
        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)

// http请求后拦截
instance.interceptors.response.use(
    (response) => {
        // console.log(response)
        // if (response.data.code != 200){
        //     let MSG = '服务器错误';
        //     ElMessageBox.alert(MSG, "提示", {
        //         confirmButtonText: '确定',
        //         type: 'warning'
        //     })
        // }
        return response
    },
    (error) => {
        if (error.response) {
            let ERRS = error.response.status
            let MSG = '服务器错误'
            ElMessageBox.alert(MSG, '提示', {
                confirmButtonText: '确定',
                type: 'warning'
            })
                .then((res) => {
                    // 跳转到登录界面
                    window.location.href = '/'
                })
                .catch((err) => {
                    window.location.href = '/'
                })
        }
        console.log(error)
        return Promise.reject(error.response.data)
    }
)

export default instance
