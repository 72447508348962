<template>
    <router-view></router-view>
</template>

<script setup>
// 以下是改造原生 ResizeObserver 的防抖函数，对付窗口 resize 时触发的报错
const debounce = (callback, delay) => {
    let tid
    return (...args) => {
        if (tid) {
            clearTimeout(tid)
        }
        tid = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

// Backup the original ResizeObserver constructor
const OriginalResizeObserver = window.ResizeObserver

// Define a new class with a descriptive name that indicates it's a debounced version
window.ResizeObserver = class DebouncedResizeObserver extends OriginalResizeObserver {
    constructor(callback) {
        // Apply the debounce function to the callback and then pass it to the super constructor
        const debouncedCallback = debounce(callback, 20)
        super(debouncedCallback)
    }
}
</script>